import React from 'react'

const BannerSocial = (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Social Media</h1>
    <i><h1>Redes Sociales</h1></i>
            </header>
            <div className="content">
           
            </div>
        </div>
    </section>
)

export default BannerSocial
